import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {
  Container,
  Headline,
  Subline,
  StyledSection,
  StyledButton,
} from "../components/layout/layoutComponents"
import { BlogLink } from "../components/blog/styledBlog"
import Photo from "../images/blog-list.png"
import leftpic from "../templates/orangeRing.png"
import rightpic from "../templates/blackRing.png"

const BlackRing = styled.div`
  overflow: hidden;
  @media (min-width: 1298px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: -155px;
    width: 250px;
    height: 250px;
    background: url(${rightpic});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    width: 350px;
    height: 350px;
  }
`
const OrangeRing = styled.div`
  display: none;
  @media (min-width: 1800px) {
    display: block;
    position: absolute;
    top: 900px;
    left: -150px;
    width: 590px;
    height: 590px;
    background: url(${leftpic});
    background-size: cover;
  }
`

const StyledHero = styled.img`
  margin-top: -20px;
  width: 100%;
  max-height: 805px;
`
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1320px;
  margin: 0 auto;
  padding: 50px 0;
`

const Blogs = styled.div`
  margin: 80px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1280px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
`

const BlogPost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 600px;
  color: #3c3c3b;
  margin-bottom: 55px;
  margin-right: 10px;
  h2 {
    font-size: 30px;
  }
  p {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 15px 0;
  }
  img {
    max-width: 674px;
    max-height: 294px;
    object-fit: cover;
  }
`

const BlogList = () => (
  <StaticQuery
    query={graphql`
      query {
        blog: allCraftBlogBlogNewsEntry(filter: { language: { eq: "en-US" } }) {
          nodes {
            id
            title
            excerpt
            slug
            dateCreated
            articleRight
            image {
              url
            }
          }
        }
      }
    `}
    render={({ blog }) => (
      <Layout>
        <SEO title="Avance Blog" />
        <StyledSection style={{ position: `relative`, overflow: `hidden` }}>
          <OrangeRing />
          <BlackRing />
          <Container>
            <StyledHero src={Photo} alt="blog posts" />
            <Wrapper>
              <Headline>LATEST ARTICLES</Headline>
              <Subline
                style={{
                  textTransform: `inherit`,
                  fontSize: `26px`,
                  fontWeight: `600`,
                  margin: `10px 0`,
                }}
              >
                A collection of stories about our expertise on consultancy, our
                mission for organizational resilience, and the ever-changing
                face of the business world.
              </Subline>
              <Blogs>
                {/* <pre>{JSON.stringify(blog, null, 2)}</pre> */}
                {blog.nodes.map(item => (
                  <BlogPost key={item.id}>
                    <img src={item.image[0]?.url} alt={item.title} />
                    <h2>{item.title}</h2>
                    <p>{item.excerpt}</p>
                    <StyledButton style={{ maxWidth: `131px` }}>
                      <BlogLink to={item.slug}>Read more</BlogLink>
                    </StyledButton>
                  </BlogPost>
                ))}
              </Blogs>
            </Wrapper>
          </Container>
        </StyledSection>
      </Layout>
    )}
  />
)

export default BlogList
