import { Link } from "gatsby"
import styled from "styled-components"

export const SytledDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 1320px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1.2px;
  p {
    color: #3c3c3b;
    margin: 0;
    font-weight: 300;
  }
  span {
    color: #f28e00;
    text-transform: uppercase;
    font-weight: 600;
  }
`

export const BlogFeed = styled.div`
  margin: 0 center;
  padding-bottom: 55px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`

export const BlogItem1 = styled.div`
  grid-area: 1 / 1 / 4 / 3;
  position: relative;
  max-height: 500px;
  display: flex;
  margin: 10px;
`
export const BlogItem2 = styled.div`
  grid-area: 1 / 3 / 2 / 4;
  position: relative;
  min-width: 320px;
  display: flex;
  margin: 10px;
`
export const BlogItem3 = styled.div`
  grid-area: 2 / 3 / 3 / 4;
  position: relative;
  min-width: 320px;
  display: flex;
  margin: 10px;
`
export const BlogItem4 = styled.div`
  grid-area: 3 / 3 / 4 / 4;
  position: relative;
  min-width: 320px;
  display: flex;
  margin: 10px;
`
export const BlogItem5 = styled.div`
  grid-area: 1 / 4 / 4 / 6;
  position: relative;
  max-height: 500px;
  display: flex;
  margin: 10px;
`
export const BlogImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`
export const BlogOverlay = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 55px 25px;
  z-index: 5000;
  opacity: 0;
  background-color: rgba(242, 142, 0, 0.7);
  &:hover {
    background-color: rgba(242, 142, 0, 0.7);
    opacity: 1;
    cursor: pointer;
  }
  h6 {
    font-size: 16px;
    margin: 0;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 18px;
  }
`
export const BlogLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`
